@import '../../_css/common';

.inputWrap {
  width: 100%;
}

.input {
  width: 100%;
  font-family: system-ui;
  border-radius: 0;
  box-shadow: none;
  border: none;
  background: none;
  background-color: var(--theme-elevation-100);
  color: var(--theme-elevation-1000);
  height: calc(var(--base) * 2);
  line-height: calc(var(--base) * 2);
  padding: 0 calc(var(--base) / 2);

  &:focus {
    border: none;
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--theme-text);
    -webkit-box-shadow: 0 0 0px 1000px var(--theme-elevation-150) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

@media (prefers-color-scheme: dark) {
  .input {
    background-color: var(--theme-elevation-150);
  }
}

.error {
  background-color: var(--theme-error-150);
}

.label {
  margin-bottom: 0;
  display: block;
  line-height: 1;
  margin-bottom: calc(var(--base) / 2);
}

.errorMessage {
  font-size: small;
  line-height: 1.25;
  margin-top: 4px;
  color: red;
}
